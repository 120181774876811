/**
 * Login module routing configuration.
 *
 * @unstable
 */

import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, UrlSegment, UrlMatchResult } from '@angular/router';
import { AuthApp } from '@auth/auth.component';
import { AuthGuard } from '@auth/auth.guard';
import { AuthInvite } from '@auth/invite.resolve';

export function authMatcher(segments: UrlSegment[]): UrlMatchResult | null {
  if (segments.length === 0) {
    return null;
  }

  let posParams;

  const posParam = segments[1] && new UrlSegment(decodeURIComponent(segments[1]?.path).trim(), {});

  switch (segments[0]?.path) {
    case 'logout':
    case 'payment':
      return { consumed: segments };

    case 'login':
      if (posParam) {
        posParams = { ssoDomain: posParam };
      }

      return { consumed: segments, posParams };

    case 'invite':
    case 'signup':
      if (posParam) {
        posParams = { inviteKey: posParam };
      }

      return { consumed: segments, posParams };

    case 'sca':
    case 'private':
      if (posParam) {
        posParams = { linkKey: posParam };
      }

      return { consumed: segments, posParams };

    case 'verify':
      if (posParam) {
        posParams = { verificationCode: posParam };
      }

      return { consumed: segments, posParams };

    default:
      return null;
  }
}

const AuthRoutes: Routes = [
  { path: '', component: AuthApp, canActivate: [AuthGuard], resolve: { invite: AuthInvite } },
];

export const AuthRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(AuthRoutes);

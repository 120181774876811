/**
 * Resolves invite data from the Firebase.
 *
 * @unstable
 */

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Store } from '@ngxs/store';

import { GetInvite } from '@shared/states/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthInvite {
  constructor(private store: Store) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<null> {
    const inviteKey = route.params?.inviteKey || route.queryParams?.inviteKey;

    return this.store.dispatch(new GetInvite(inviteKey)).pipe(map(() => null));
  }
}
